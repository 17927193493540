import axios from "axios";
import { getCurrentUser } from "./AuthService";

const BASE_URL_MAP = {
  // localhost: "http://localhost/api/backoffice",
  // localhost: "https://portalcorretor.guerini.com.br/api/backoffice",
  "plataforma.guerini.com.br": `https://portalcorretor.guerini.com.br/api/backoffice`,
  "portaldoadministrador.guerini.com.br": `https://portalcorretor.guerini.com.br/api/backoffice`,
};

const baseURL =
  BASE_URL_MAP[window.location.hostname] ||
  BASE_URL_MAP["portaldoadministrador.guerini.com.br"];

const api = axios.create({
  baseURL,
  withCredentials: true,
});

api.interceptors.request.use(async (config) => {
  const user = await getCurrentUser();

  if (!user) return config;

  const tokenResult = await user.getIdTokenResult();
  config.headers.Authorization = `Bearer ${tokenResult.token}`;

  return config;
});

export { api, baseURL };
