import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
  MenuItem,
  Paper as MuiPaper,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditorsTabs from "./EditorsTabs";

import { getCurrentUser } from "../services/AuthService";
import { BpProjects } from "../services";

const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const Paper = styled(MuiPaper)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  height: 65px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
  width: 65px;
`;

const configDefaultState = {
  published: false,
  publishedForAdmin: false,
  available: false,
  availableForAdmin: false,
  highlight: false,
  highlightForAdmin: false,
  tableEnabled: false,
  tableEnabledForAdmin: false,
  mapEnabled: false,
  mapEnabledForAdmin: false,
  nativeMapEnabled: false,
  nativeMapEnabledForAdmin: false,
  showStatus: false,
  showStatusForAdmin: false,
  simulatorEnabled: false,
  simulatorEnabledForAdmin: false,
  simulationForReservedLotEnabled: false,
  simulationForReservedLotEnabledForAdmin: false,
  simulationForSoldLotEnabled: false,
  simulationForSoldLotEnabledForAdmin: false,
  intermediarySimulationEnabled: false,
  intermediarySimulationEnabledForAdmin: false,
  preReservationEnabled: false,
  preReservationEnabledForAdmin: false,
  blockPreReservationConfirmation: false,
  blockPreReservationConfirmationForAdmin: false,
  reservationEnabled: false,
  reservationEnabledForAdmin: false,
  reservationForReservedLotEnabled: false,
  reservationForReservedLotEnabledForAdmin: false,
  reservationByProtocolOnly: false,
  reservationByProtocolOnlyForAdmin: false,
  selfMapLinkEnabled: false,
  selfMapLinkEnabledForAdmin: false,
  rouletteEnabled: false,
  rouletteEnabledForAdmin: false,
  soldValueVisible: false,
  soldValueVisibleForAdmin: false,
  proposalWithoutSimulationEnabled: false,
  proposalWithoutSimulationEnabledForAdmin: false,
  showSimulationPriceWarning: false,
  showSimulationPriceWarningForAdmin: false,
  createBy: "",
};

const pageDataInitialState = {
  index: [],
  content: {},
};

function ProjectsForm({
  data,
  buttonName,
  handleSubmit,
  disabledFieldURL,
  projectId,
}) {
  const [pageData, setPageData] = React.useState(pageDataInitialState);
  const [portalDescription, setPortalDescription] = React.useState("");
  const [portalUrl, setPortalUrl] = React.useState("");
  const [name, setName] = useState("");
  const [group, setGroup] = useState("");
  const [city, setCity] = useState("");
  const [code, setCode] = useState("");
  const [kind, setKind] = useState("");
  const [mapUrl, setMapUrl] = useState("");
  const [sharedMapUrl, setSharedMapUrl] = useState("");
  const [googleSheetFetchInterval, setGoogleSheetFetchInterval] = useState("");
  const [googleSheetUrl, setGoogleSheetUrl] = useState("");
  const [nativeMapLatitude, setNativeMapLatitude] = useState("");
  const [nativeMapLongitute, setNativeMapLongitute] = useState("");
  const [nativeMapZoom, setNativeMapZoom] = useState(17);
  const [nativeMapOverlayImageUrl, setNativeMapOverlayImageUrl] = useState("");
  const [
    nativeMapOverlayImageLeftLatitude,
    setNativeMapOverlayImageLeftLatitude,
  ] = useState("");
  const [
    nativeMapOverlayImageLeftLongitude,
    setNativeMapOverlayImageLeftLongitude,
  ] = useState("");
  const [
    nativeMapOverlayImageRightLatitude,
    setNativeMapOverlayImageRightLatitude,
  ] = useState("");
  const [
    nativeMapOverlayImageRightLongitude,
    setNativeMapOverlayImageRightLongitude,
  ] = useState("");
  const [warningText, setWarningText] = useState("");
  const [warningTextForAdmin, setWarningTextForAdmin] = useState("");

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videosToRemove, setVideosToRemove] = useState([]);
  const [youtubePlaylistUrl, setYoutubePlaylistUrl] = useState("");

  const [errors, setErrors] = useState(null);

  const [config, setConfig] = React.useState(configDefaultState);

  const [isSubmitting, setSubmitting] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectLinks, setProjectLinks] = useState({});

  useEffect(() => {
    if (data?.links) {
      setProjectLinks(
        data?.links?.reduce(
          (acc, link) => ({ ...acc, [link.linkedProjectId]: link.enabled }),
          {}
        )
      );
    }

    setPageData(
      data.pageData ? JSON.parse(data.pageData) : pageDataInitialState
    );

    const imagesWithSrc = data?.images?.map((image) => {
      return {
        ...image,
        src: image.image.url
          ? image.image.url
          : `https://portalcorretor.guerini.com.br/api/backoffice/projects/${projectId}/images/${image.id}`,
      };
    });

    setImages(imagesWithSrc || []);
    setVideos(data?.videos || []);
    setYoutubePlaylistUrl(data?.youtubePlaylistUrl || "");
    setName(data?.name || "");
    setPortalDescription(data?.portalDescription || "");
    setPortalUrl(data?.portalUrl || "");
    setGroup(data?.group || "");
    setCity(data?.city || "");
    setCode(data?.code || "");
    setMapUrl(data?.mapUrl || "");
    setSharedMapUrl(data?.sharedMapUrl || "");
    setKind(data?.kind || "");
    setGoogleSheetUrl(data?.googleSheetUrl || "");
    setGoogleSheetFetchInterval(data?.googleSheetFetchInterval || "");
    setNativeMapLatitude(data?.nativeMapLatitude || "");
    setNativeMapLongitute(data?.nativeMapLongitute || "");
    setNativeMapZoom(data?.nativeMapZoom || 17);
    setNativeMapOverlayImageUrl(data?.nativeMapOverlayImageUrl || "");
    setNativeMapOverlayImageLeftLatitude(
      data?.nativeMapOverlayImageLeftLatitude || ""
    );
    setNativeMapOverlayImageLeftLongitude(
      data?.nativeMapOverlayImageLeftLongitude || ""
    );
    setNativeMapOverlayImageRightLatitude(
      data?.nativeMapOverlayImageRightLatitude || ""
    );
    setNativeMapOverlayImageRightLongitude(
      data?.nativeMapOverlayImageRightLongitude || ""
    );
    setWarningText(data?.warningText || "");
    setWarningTextForAdmin(data?.warningTextForAdmin || "");

    const configState = Object.keys(configDefaultState).reduce(
      (configObj, key) => {
        const configValue = data?.[key];

        return {
          ...configObj,
          [key]: Boolean(configValue),
        };
      },
      configDefaultState
    );

    setConfig(configState);

    BpProjects.list(0, null, 100).then((res) => {
      setProjects(res.data.rows);
    });
  }, [projectId, data]);

  const handleChange = (setInput, value) => {
    setInput(value);
  };

  const handleInputFileChange = (event, kind) => {
    const { files } = event.target;

    if (files.length) {
      const file = files[0];
      const imageSrc = URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = {
          name: file.name,
          imageType: file.type,
          data: reader.result,
          src: imageSrc,
          kind,
          id: images.length + 1,
        };

        const newImages = [...images, img];
        setImages(newImages);
      };
    }
  };

  const {
    published,
    available,
    availableForAdmin,
    highlight,
    highlightForAdmin,
    tableEnabled,
    tableEnabledForAdmin,
    mapEnabled,
    mapEnabledForAdmin,
    nativeMapEnabled,
    nativeMapEnabledForAdmin,
    showStatus,
    showStatusForAdmin,
    simulatorEnabled,
    simulatorEnabledForAdmin,
    simulationForReservedLotEnabled,
    simulationForReservedLotEnabledForAdmin,
    simulationForSoldLotEnabled,
    simulationForSoldLotEnabledForAdmin,
    intermediarySimulationEnabled,
    intermediarySimulationEnabledForAdmin,
    preReservationEnabled,
    preReservationEnabledForAdmin,
    blockPreReservationConfirmation,
    blockPreReservationConfirmationForAdmin,
    reservationEnabled,
    reservationEnabledForAdmin,
    reservationForReservedLotEnabled,
    reservationForReservedLotEnabledForAdmin,
    reservationByProtocolOnly,
    reservationByProtocolOnlyForAdmin,
    rouletteEnabled,
    rouletteEnabledForAdmin,
    selfMapLinkEnabled,
    selfMapLinkEnabledForAdmin,
    soldValueVisible,
    soldValueVisibleForAdmin,
    proposalWithoutSimulationEnabled,
    proposalWithoutSimulationEnabledForAdmin,
    publishedForAdmin,
    showSimulationPriceWarning,
    showSimulationPriceWarningForAdmin,
  } = config;

  const handleConfigChange = (name, value) => {
    setConfig({ ...config, [name]: value });
  };

  const onAddVideoClick = () => {
    setVideos([...videos, { id: null, youtubeUrl: "" }]);
  };

  const onVideoRemoveClick = (index) => {
    const video = videos.splice(index, 1)[0];

    setVideos([...videos]);
    if (video.id) {
      setVideosToRemove([...videosToRemove, video.id]);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    const user = await getCurrentUser();

    const newImages = images
      .filter((image) => image.data)
      .filter((image) => !image.remove)
      .map((image) => ({
        imageType: image.imageType,
        kind: image.kind,
        data: image.data,
        name: image.name,
      }));

    const deleteImages = images
      .filter((image) => image.remove)
      .map((image) => image.id);

    setSubmitting(true);
    const form = {
      ...config,
      code,
      deleteImages,
      deleteVideos: videosToRemove.map((p) => parseInt(p)),
      googleSheetFetchInterval: Number.parseInt(googleSheetFetchInterval),
      googleSheetUrl,
      group,
      city,
      kind,
      mapUrl,
      name,
      nativeMapLatitude: Number(nativeMapLatitude),
      nativeMapLongitute: Number(nativeMapLongitute),
      nativeMapZoom: Number.parseInt(nativeMapZoom),
      nativeMapOverlayImageLeftLatitude: Number(
        nativeMapOverlayImageLeftLatitude
      ),
      nativeMapOverlayImageLeftLongitude: Number(
        nativeMapOverlayImageLeftLongitude
      ),
      nativeMapOverlayImageRightLatitude: Number(
        nativeMapOverlayImageRightLatitude
      ),
      nativeMapOverlayImageRightLongitude: Number(
        nativeMapOverlayImageRightLongitude
      ),
      nativeMapOverlayImageUrl,
      newImages,
      newVideos: videos.filter(
        (v) => v.id === null && v.youtubeUrl !== null && v.youtubeUrl.length > 0
      ),
      pageData: JSON.stringify(pageData),
      portalDescription,
      portalUrl,
      sharedMapUrl,
      warningText,
      warningTextForAdmin,
      rouletteEnabled,
      rouletteEnabledForAdmin,
      youtubePlaylistUrl,
      createBy: user.displayName,
      links: Object.keys(projectLinks).map((linkedProjectId) => ({
        linkedProjectId: linkedProjectId,
        enabled: projectLinks[linkedProjectId],
      })),
    };

    try {
      await handleSubmit(form);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const removeImage = (id) => {
    const newImages = images.map((image) => {
      if (image.id === id) {
        image.remove = true;
      }
      return image;
    });
    setImages(newImages);
  };

  const nonDeletedImages = images.filter((image) => !image.remove);
  const banners = nonDeletedImages.filter((image) => image.kind === "banner");
  const pictures = nonDeletedImages.filter((image) => image.kind === "picture");

  return isSubmitting ? (
    <CircularProgress variant="indeterminate" color="red" />
  ) : (
    <form>
      <Grid container spacing={6}>
        <Grid item md={4}>
          <TextField
            label="Nome do empreendimento"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(setName, event.target.value)}
            value={name}
            my={2}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            id="grouper"
            label="Agrupador"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(setGroup, event.target.value)}
            value={group}
            my={2}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            id="city"
            label="Cidade"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(setCity, event.target.value)}
            value={city}
            my={2}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <TextField
            label="Codigo do Empreendimento no SP7"
            id="project"
            variant="outlined"
            fullWidth
            onChange={(event) => handleChange(setCode, event.target.value)}
            value={code}
            my={2}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label="Tipo"
            onChange={(event) => handleChange(setKind, event.target.value)}
            value={kind}
            select
            my={2}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="residential">Residencial</MenuItem>
            <MenuItem value="business">Empresarial</MenuItem>
            <MenuItem value="area">Area</MenuItem>
            <MenuItem value="mixed">Misto</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item md={6}>
          <TextField
            label="CV - URL google sheet"
            id="url"
            variant="outlined"
            onChange={(event) =>
              handleChange(setGoogleSheetUrl, event.target.value)
            }
            value={googleSheetUrl}
            fullWidth
            disabled={disabledFieldURL}
            my={2}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label="CV - Tempo de atualização"
            select
            my={2}
            onChange={(event) =>
              handleChange(setGoogleSheetFetchInterval, event.target.value)
            }
            value={googleSheetFetchInterval}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="60">A cada 1 minuto</MenuItem>
            <MenuItem value="300">A cada 5 minutos</MenuItem>
            <MenuItem value="-1">Não sincronizar</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <TextField
        id="URL Map"
        label="URL Mapa"
        variant="outlined"
        onChange={(event) => handleChange(setMapUrl, event.target.value)}
        value={mapUrl}
        fullWidth
        my={2}
      />

      <TextField
        id="map"
        label="URL Mapa Cliente (Compartilhável)"
        onChange={(event) => handleChange(setSharedMapUrl, event.target.value)}
        value={sharedMapUrl}
        variant="outlined"
        fullWidth
        my={2}
        mb={8}
      />

      <Typography variant="h6">Mapa Nativo</Typography>

      <Grid container spacing={6}>
        <Grid item md={6}>
          <TextField
            label="Mapa Nativo Imagem (Overlay)"
            variant="outlined"
            onChange={(event) =>
              handleChange(setNativeMapOverlayImageUrl, event.target.value)
            }
            value={nativeMapOverlayImageUrl}
            fullWidth
            my={4}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            label="Latitude"
            variant="outlined"
            onChange={(event) =>
              handleChange(setNativeMapLatitude, event.target.value)
            }
            value={nativeMapLatitude}
            fullWidth
            my={4}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            label="Longitude"
            variant="outlined"
            onChange={(event) =>
              handleChange(setNativeMapLongitute, event.target.value)
            }
            value={nativeMapLongitute}
            fullWidth
            my={4}
          />
        </Grid>
        <Grid item md={2}>
          <TextField
            label="Zoom"
            select
            my={4}
            onChange={(event) =>
              handleChange(setNativeMapZoom, event.target.value)
            }
            value={nativeMapZoom}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="5">4</MenuItem>
            <MenuItem value="6">5</MenuItem>
            <MenuItem value="7">6</MenuItem>
            <MenuItem value="8">7</MenuItem>
            <MenuItem value="9">8</MenuItem>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="11">11</MenuItem>
            <MenuItem value="12">12</MenuItem>
            <MenuItem value="13">13</MenuItem>
            <MenuItem value="14">14</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="16">16</MenuItem>
            <MenuItem value="17">17</MenuItem>
            <MenuItem value="18">18</MenuItem>
            <MenuItem value="19">19</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item md={3}>
          <TextField
            label="Imagem - Latitude Esquerda (Sul)"
            variant="outlined"
            onChange={(event) =>
              handleChange(
                setNativeMapOverlayImageLeftLatitude,
                event.target.value
              )
            }
            value={nativeMapOverlayImageLeftLatitude}
            fullWidth
            my={2}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            label="Imagem - Longitude Esquerda (Oeste)"
            variant="outlined"
            onChange={(event) =>
              handleChange(
                setNativeMapOverlayImageLeftLongitude,
                event.target.value
              )
            }
            value={nativeMapOverlayImageLeftLongitude}
            fullWidth
            my={2}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            label="Imagem - Latitude Direita (Norte)"
            onChange={(event) =>
              handleChange(
                setNativeMapOverlayImageRightLatitude,
                event.target.value
              )
            }
            value={nativeMapOverlayImageRightLatitude}
            variant="outlined"
            fullWidth
            my={2}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            label="Imagem - Longitude Direita (Leste)"
            onChange={(event) =>
              handleChange(
                setNativeMapOverlayImageRightLongitude,
                event.target.value
              )
            }
            value={nativeMapOverlayImageRightLongitude}
            variant="outlined"
            fullWidth
            my={2}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" style={{ margin: "16px 0" }}>
        Vincular mapas de outros empreendimentos
      </Typography>

      <Grid container>
        {projects?.map((project) => (
          <Grid item md={3} key={`linked_project_${project.id}`}>
            <FormControlLabel
              disabled={parseInt(project.id) === parseInt(projectId)}
              control={
                <Checkbox
                  checked={projectLinks[project.id] === true}
                  disabled={parseInt(project.id) === parseInt(projectId)}
                  onChange={(event) => {
                    setProjectLinks({
                      ...projectLinks,
                      [project.id]: event.target.checked,
                    });
                  }}
                />
              }
              label={project.name}
            />
          </Grid>
        ))}
      </Grid>

      <Typography variant="h6" style={{ margin: "16px 0" }}>
        Informações do Mapa de Pesquisa
      </Typography>

      <Grid container spacing={6}>
        <Grid item md={6}>
          <Typography>Descrição do Mapa de Pesquisa</Typography>
          <TextField
            label="Texto que vai no mapa do portal "
            variant="outlined"
            onChange={(event) =>
              handleChange(setPortalDescription, event.target.value)
            }
            value={portalDescription}
            fullWidth
            my={2}
          />
        </Grid>
        <Grid item md={6}>
          <Typography>URL do Mapa de Pesquisa</Typography>
          <TextField
            label="https://portal.guerini.com.br"
            onChange={(event) => handleChange(setPortalUrl, event.target.value)}
            value={portalUrl}
            variant="outlined"
            fullWidth
            my={2}
          />
        </Grid>
      </Grid>

      <Grid container style={{ paddingTop: "16px" }}>
        <Grid md={6}>
          <Paper>
            <FormControl component="fieldset">
              <Typography variant="h8">
                <strong>Configurações (público)</strong>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={published}
                      onChange={(event) =>
                        handleConfigChange("published", event.target.checked)
                      }
                      name="published"
                    />
                  }
                  label="Publicado"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={available}
                      onChange={(event) =>
                        handleConfigChange("available", event.target.checked)
                      }
                      name="available"
                    />
                  }
                  label="Disponível"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={highlight}
                      onChange={(event) =>
                        handleConfigChange("highlight", event.target.checked)
                      }
                      name="highlight"
                    />
                  }
                  label="Destacar"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tableEnabled}
                      onChange={(event) =>
                        handleConfigChange("tableEnabled", event.target.checked)
                      }
                      name="tableEnabled"
                    />
                  }
                  label="Habilitar tabela"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mapEnabled}
                      onChange={(event) =>
                        handleConfigChange("mapEnabled", event.target.checked)
                      }
                      name="mapEnabled"
                    />
                  }
                  label="Habilitar mapa"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nativeMapEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "nativeMapEnabled",
                          event.target.checked
                        )
                      }
                      name="nativeMapEnabled"
                    />
                  }
                  label="Habilitar mapa nativo"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selfMapLinkEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "selfMapLinkEnabled",
                          event.target.checked
                        )
                      }
                      name="selfMapLinkEnabled"
                    />
                  }
                  label="Habilitar link para mapa de autoatendimento"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showStatus}
                      onChange={(event) =>
                        handleConfigChange("showStatus", event.target.checked)
                      }
                      name="showStatus"
                    />
                  }
                  label="Mostrar status"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={simulatorEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "simulatorEnabled",
                          event.target.checked
                        )
                      }
                      name="simulatorEnabled"
                    />
                  }
                  label="Habilitar simulador(Á venda)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={simulationForReservedLotEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "simulationForReservedLotEnabled",
                          event.target.checked
                        )
                      }
                      name="simulationForReservedLotEnabled"
                    />
                  }
                  label="Habilitar simulador(Reservado)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={simulationForSoldLotEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "simulationForSoldLotEnabled",
                          event.target.checked
                        )
                      }
                      name="simulationForSoldLotEnabled"
                    />
                  }
                  label="Habilitar simulador(Vendido)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intermediarySimulationEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "intermediarySimulationEnabled",
                          event.target.checked
                        )
                      }
                      name="intermediarySimulationEnabled"
                    />
                  }
                  label="Habilitar intermediarias pelo simulador"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={preReservationEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "preReservationEnabled",
                          event.target.checked
                        )
                      }
                      name="preReservationEnabled"
                    />
                  }
                  label="Habilitar pré-reserva"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blockPreReservationConfirmation}
                      onChange={(event) =>
                        handleConfigChange(
                          "blockPreReservationConfirmation",
                          event.target.checked
                        )
                      }
                      name="blockPreReservationConfirmation"
                    />
                  }
                  label="Bloquear confirmação de pré-reserva"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservationEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "reservationEnabled",
                          event.target.checked
                        )
                      }
                      name="reservationEnabled"
                    />
                  }
                  label="Habilitar reserva pelo simulador (Á venda)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservationForReservedLotEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "reservationForReservedLotEnabled",
                          event.target.checked
                        )
                      }
                      name="reservationForReservedLotEnabled"
                    />
                  }
                  label="Habilitar intermediarias pelo simulador (Reservado)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservationByProtocolOnly}
                      onChange={(event) =>
                        handleConfigChange(
                          "reservationByProtocolOnly",
                          event.target.checked
                        )
                      }
                      name="reservationByProtocolOnly"
                    />
                  }
                  label="Reserva apenas por protocolo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={soldValueVisible}
                      onChange={(event) =>
                        handleConfigChange(
                          "soldValueVisible",
                          event.target.checked
                        )
                      }
                      name="soldValueVisible"
                    />
                  }
                  label="Mostrar valor de lote vendido"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={proposalWithoutSimulationEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "proposalWithoutSimulationEnabled",
                          event.target.checked
                        )
                      }
                      name="proposalWithoutSimulationEnabled"
                    />
                  }
                  label="Permitir abrir chamado sem simulação"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showSimulationPriceWarning}
                      onChange={(event) =>
                        handleConfigChange(
                          "showSimulationPriceWarning",
                          event.target.checked
                        )
                      }
                      name="showSimulationPriceWarning"
                    />
                  }
                  label="Mostrar alerta de preço de simulação"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rouletteEnabled}
                      onChange={(event) =>
                        handleConfigChange(
                          "rouletteEnabled",
                          event.target.checked
                        )
                      }
                      name="rouletteEnabled"
                    />
                  }
                  label="Habilitar roleta virtual"
                />
              </FormGroup>
            </FormControl>
            <Grid item md={8}>
              <TextField
                name="warningText"
                label="Mensagem de alerta"
                placeholder="Deixar em branco caso não queira alerta"
                onChange={(event) =>
                  handleChange(setWarningText, event.target.value)
                }
                value={warningText}
                error={errors && errors.warningText}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Paper>
        </Grid>

        <Grid md={6} style={{ paddingLeft: "12px" }}>
          <Paper>
            <FormControl component="fieldset">
              <Typography variant="h8">
                <strong>Configurações (administrativo)</strong>
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={publishedForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "publishedForAdmin",
                          event.target.checked
                        )
                      }
                      name="publishedForAdmin"
                    />
                  }
                  label="Publicado"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={availableForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "availableForAdmin",
                          event.target.checked
                        )
                      }
                      name="availableForAdmin"
                    />
                  }
                  label="Disponível"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={highlightForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "highlightForAdmin",
                          event.target.checked
                        )
                      }
                      name="highlightForAdmin"
                    />
                  }
                  label="Destacar"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tableEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "tableEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="tableEnabledForAdmin"
                    />
                  }
                  label="Habilitar tabela"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mapEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "mapEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="mapEnabledForAdmin"
                    />
                  }
                  label="Habilitar mapa"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nativeMapEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "nativeMapEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="nativeMapEnabledForAdmin"
                    />
                  }
                  label="Habilitar mapa nativo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selfMapLinkEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "selfMapLinkEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="selfMapLinkEnabledForAdmin"
                    />
                  }
                  label="Habilitar link para mapa de autoatendimento"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showStatusForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "showStatusForAdmin",
                          event.target.checked
                        )
                      }
                      name="showStatusForAdmin"
                    />
                  }
                  label="Mostrar status"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={simulatorEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "simulatorEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="simulatorEnabledForAdmin"
                    />
                  }
                  label="Habilitar simulador(Á venda)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={simulationForReservedLotEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "simulationForReservedLotEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="simulationForReservedLotEnabledForAdmin"
                    />
                  }
                  label="Habilitar simulador(Reservado)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={simulationForSoldLotEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "simulationForSoldLotEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="simulationForSoldLotEnabledForAdmin"
                    />
                  }
                  label="Habilitar simulador(Vendido)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intermediarySimulationEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "intermediarySimulationEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="intermediarySimulationEnabledForAdmin"
                    />
                  }
                  label="Habilitar intermediarias pelo simulador"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={preReservationEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "preReservationEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="preReservationEnabledForAdmin"
                    />
                  }
                  label="Habilitar pré-reserva"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blockPreReservationConfirmationForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "blockPreReservationConfirmationForAdmin",
                          event.target.checked
                        )
                      }
                      name="blockPreReservationConfirmationForAdmin"
                    />
                  }
                  label="Bloquear confirmação de pré-reserva"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservationEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "reservationEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="reservationEnabledForAdmin"
                    />
                  }
                  label="Habilitar reserva pelo simulador (Á venda)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservationForReservedLotEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "reservationForReservedLotEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="reservationForReservedLotEnabledForAdmin"
                    />
                  }
                  label="Habilitar intermediarias pelo simulador (Reservado)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservationByProtocolOnlyForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "reservationByProtocolOnlyForAdmin",
                          event.target.checked
                        )
                      }
                      name="reservationByProtocolOnlyForAdmin"
                    />
                  }
                  label="Reserva apenas por protocolo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={soldValueVisibleForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "soldValueVisibleForAdmin",
                          event.target.checked
                        )
                      }
                      name="soldValueVisibleForAdmin"
                    />
                  }
                  label="Mostrar valor de lote vendido"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={proposalWithoutSimulationEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "proposalWithoutSimulationEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="proposalWithoutSimulationEnabledForAdmin"
                    />
                  }
                  label="Permitir abrir chamado sem simulação"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showSimulationPriceWarningForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "showSimulationPriceWarningForAdmin",
                          event.target.checked
                        )
                      }
                      name="showSimulationPriceWarningForAdmin"
                    />
                  }
                  label="Mostrar alerta de preço de simulação"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rouletteEnabledForAdmin}
                      onChange={(event) =>
                        handleConfigChange(
                          "rouletteEnabledForAdmin",
                          event.target.checked
                        )
                      }
                      name="rouletteEnabledForAdmin"
                    />
                  }
                  label="Habilitar roleta virtual"
                />
              </FormGroup>
            </FormControl>
            <Grid item md={8}>
              <TextField
                label="Mensagem de alerta"
                name="warningTextForAdmin"
                variant="outlined"
                placeholder="Deixar em branco caso não queira alerta"
                onChange={(event) =>
                  handleChange(setWarningTextForAdmin, event.target.value)
                }
                value={warningTextForAdmin}
                error={errors && errors.warningTextForAdmin}
                fullWidth
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <EditorsTabs pageData={pageData} setPageData={setPageData} />

      <Grid item md={12} my={6}>
        <div>
          <div>
            <Typography variant="h6" my={14} gutterBottom>
              <strong>
                <h4>Imagens</h4>
              </strong>
            </Typography>
            <Typography>
              <strong>Banner (1500x700)</strong>
            </Typography>
          </div>
          <div align="right">
            <Button>Remover banner</Button>
          </div>
        </div>
        <CenteredContent>
          <BigAvatar alt="Remy Sharp" src="/static/img/avatars/upload.png" />
          <input
            accept="image/*"
            id="bg"
            multiple
            name="bg"
            onChange={(event) => handleInputFileChange(event, "banner")}
            style={{ display: "none" }}
            type="file"
          />

          <label htmlFor="bg">
            <Button variant="contained" color="primary" component="span">
              Escolher imagem para banner
            </Button>

            <Typography variant="caption" display="block" gutterBottom>
              Tamanho máximo: 5mb, Extensões: jpg, jpeg, png
            </Typography>
          </label>

          {banners.map((banner) => (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                alt="background image"
                height="200px"
                src={banner.src}
                width="auto"
              />
              <IconButton onClick={() => removeImage(banner.id)}>
                <HighlightOffIcon />
                <Typography variant="caption" gutterBottom color="error">
                  Remover imagem
                </Typography>
              </IconButton>
            </div>
          ))}
        </CenteredContent>
      </Grid>
      <Grid item md={12}>
        <div>
          <Typography variant="h6" my={4} gutterBottom>
            <strong>
              <h4>Fotos</h4>
            </strong>
          </Typography>

          <div align="right">
            <Button>Remover selecionados</Button>
          </div>
        </div>
        <CenteredContent>
          <BigAvatar alt="Remy Sharp" src="/static/img/avatars/upload.png" />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="photos"
            multiple
            name="photos"
            type="file"
            onChange={(event) => handleInputFileChange(event, "picture")}
          />
          <label htmlFor="photos">
            <Button variant="contained" color="primary" component="span">
              Escolher fotos
            </Button>

            <Typography variant="caption" display="block" gutterBottom>
              Tamanho máximo: 5mb, Extensões: jpg, jpeg, png
            </Typography>
          </label>
          <div
            style={{
              display: "flex",
              marginTop: "22px",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {pictures.map((picture) => (
              <div style={{ position: "relative", margin: "0 10px" }}>
                <img
                  alt="background image"
                  height="150px"
                  src={picture.src}
                  width="230px"
                />
                <IconButton
                  onClick={() => removeImage(picture.id)}
                  style={{
                    display: "block",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                >
                  <HighlightOffIcon
                    style={{
                      backgroundColor: "rgba(0,0,0,0.2)",
                      borderRadius: 50,
                    }}
                  />
                </IconButton>
              </div>
            ))}
          </div>
        </CenteredContent>
      </Grid>
      <Grid item md={12}>
        <Typography variant="h6" my={4} gutterBottom>
          <strong>
            <h4>Videos</h4>
          </strong>
        </Typography>

        {videos.map((video, index) => (
          <Grid key={index} item md={12}>
            <Typography>
              <strong>Vídeo #{index + 1}</strong>
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              disabled={video.id}
              placeholder="https://www.youtube.com/playlist?list=PLysw5xzni8nNBlq8qFdAUjRDzO-T2ds4d"
              onChange={(event) => {
                const newVideos = [...videos];
                newVideos[index].youtubeUrl = event.target.value;
                setVideos(newVideos);
              }}
              value={video.youtubeUrl}
            />

            <div align="right">
              <Button
                type="button"
                link
                onClick={() => {
                  onVideoRemoveClick(index);
                }}
                style={{ color: "#F00" }}
              >
                Remover vídeo
              </Button>
            </div>
          </Grid>
        ))}

        <div align="right">
          <Button type="button" link onClick={onAddVideoClick}>
            Adicionar Videos
          </Button>
        </div>
      </Grid>

      <Button
        onClick={submitForm}
        variant="contained"
        fullWidth
        color="primary"
        mt={3}
      >
        {buttonName}
      </Button>
    </form>
  );
}

export default ProjectsForm;
